<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <h3>Roles</h3>
        </CCardHeader>
         <template>
              <CRow>
                <CCol col="3" class="ml-3 mt-1">
                  <router-link to="/roles/crear" v-if="$can('rol.create')">
                    <CButton color="primary">Crear Nuevo Rol</CButton>
                  </router-link>
                    
                </CCol>
              </CRow>   
         </template>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
            <!--<template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{data.item.status}}
                </CBadge>
              </td>
            </template>-->
            <template #accion="data">
               <td >
                <CButton color="warning" title="Editar" @click="editarRol(data.item.id)" v-if="$can('rol.edit')"><CIcon name="cil-pencil"/></CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'Roles',
  data () {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Rol', _classes: 'font-weight-bold' },
        { key: 'url',label:'Url' },
        { key: 'accion',label:'Acciones',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    /*getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },*/
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    /*beforeMount(){
        this.getRoles();
    },*/
    getRoles(){
      this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/roles').then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    editarRol(id) {
      this.$router.push({path: `roles/editar/${id}`}).catch(() => {});
    }
  },
  mounted(){
    /*this.$nextTick(function () {
      this.getRoles();
    })*/
    setTimeout(() => {
      this.getRoles();
    }, 200);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  }
}
</script>
